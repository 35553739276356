.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
}

.pagination .page-number {
  padding: 8px 15px;
  cursor: border-radius 3px;
  font-weight: 400;
}

.pagination .page-number:hover {
  background-color: #5ab1bb;
  color: #fff;
}

.pagination .active {
  background-color: #5ab1bb;
}
.page-number {
  text-decoration: none;
}
